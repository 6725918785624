import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import Bigmenu from '../assets/images/bigmenu.jpg';
import Littlemenus from '../assets/images/littlemenus.jpg';
import Signednapkins from '../assets/images/signednapkins.jpg';
import Ashtrays from '../assets/images/ashtrays.jpg';
import Keychains from '../assets/images/keychains.jpg';
import Mug from '../assets/images/mug.jpg';
import Hhlisting from '../assets/images/hhlisting.jpg';

export default function SouvenirsPage() {
  return (
    <>
      <SEO title="Souvenirs" />
      <div>
        <p>
          The Souvenirs Page! This is the place to catch a glimpse of all the
          Huddle House souvenirs we have aquired throughout our travels. We love
          all Huddle House and try to get unique things from each one. Check out
          what we have aquired so far. (Note that all of these items were given
          to us - we don't steal from Huddle House!)
        </p>
      </div>
      <ImageGrid>
        <div>
          <img src={Bigmenu} alt="Actual Huddle House Menu from Oxford 2" />
          <ImageCaption>Actual Huddle House Menu from Oxford 2</ImageCaption>
        </div>
        <div>
          <img src={Littlemenus} alt="Little Signed Menus from Moultrie" />
          <ImageCaption>Little Signed Menus from Moultrie</ImageCaption>
        </div>
        <div>
          <img src={Signednapkins} alt="Signed Napkins from Lincoln & Oxford" />
          <ImageCaption>Signed Napkins from Lincoln & Oxford</ImageCaption>
        </div>
        <div>
          <img src={Ashtrays} alt="Real Huddle House Ashtrays from Moultrie" />
          <ImageCaption>Real Huddle House Ashtrays from Moultrie</ImageCaption>
        </div>
        <div>
          <img src={Keychains} alt="Keychains from Moultrie" />
          <ImageCaption>Keychains from Moultrie</ImageCaption>
        </div>
        <div>
          <img src={Mug} alt="Huddle House Mug from Talladega" />
          <ImageCaption>Huddle House Mug from Talladega</ImageCaption>
        </div>
        <div>
          <img src={Hhlisting} alt="Huddle House Location Guide" />
          <ImageCaption>Huddle House Location Guide</ImageCaption>
        </div>
      </ImageGrid>
    </>
  );
}
